.sponsorsWrapper{
  display:flex;
  max-height: 400px;
  height: 100vh;
  flex-direction: column;
  position: relative;
  width:100%;
  justify-self:center;
  padding-bottom:0px;


  .contentHeaderWrapper{
    display:flex;
    flex-direction: column;
    .titleInfo{
      display:block;
      text-align: center;
      font-family: 'Myriad Pro Light';
      color:#245d38;
      padding-bottom:10px;
    }
  }

  .sponsorsTitle{
    color:#245d38;
    font-weight: lighter;
    text-align: center;
    font-size: 2.7rem;
    span{
      color:#82bc00;
    }

  }  


  .sponsorsWrapperContent{
    display:flex;
    justify-content: space-around;
    width:100%;
    padding:80px;
    padding-bottom:0px;

    
    
  }


}