@font-face {
  font-family: 'Myriad Pro Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Regular'), url('./assets/fonts/myriadpro-regular.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Myriad Pro Condensed';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Condensed'), url('./assets/fonts/myriadpro-cond.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Myriad Pro Condensed Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Condensed Italic'), url('./assets/fonts/myriadpro-condit.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Myriad Pro Light';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Light'), url('./assets/fonts/myriadpro-light.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Myriad Pro Semibold';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Semibold'), url('./assets/fonts/myriadpro-semibold.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Myriad Pro Semibold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Semibold Italic'), url('./assets/fonts/myriadpro-semiboldit.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Myriad Pro Bold Condensed';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Bold Condensed'), url('./assets/fonts/myriadpro-boldcond.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Myriad Pro Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Bold'), url('./assets/fonts/myriadpro-bold.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Myriad Pro Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Bold Italic'), url('./assets/fonts/myriadpro-boldit.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Myriad Pro Bold Condensed Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Bold Condensed Italic'), url('./assets/fonts/myriadpro-boldcondit.woff') format('woff');
  }
  
* {
  box-sizing: border-box;
  margin:0;
  padding:0;
  font-family: 'Myriad Pro Regular', sans-serif; 
}

main{
  display:grid;
}

.react-dropdown-select{
  background:#fff;
  border-radius: 4px !important;
}
.react-dropdown-select-input{
  font-size: 1rem !important;
}

.btn--outline{
  background-color:transparent;
  color:#245d38;
  padding:8px 20px;
  border:1px solid #ededed;
  transition: all 0.3s ease-out;
  border-radius: 3px;
}