.lastArticlesWrapper{
  display:flex;
  max-height: 750px;
  height: 100vh;
  background:#fff;

  flex-direction: column;
  position: relative;
  max-width: 1175px;
  width:100%;
  justify-self:center;
  padding-top:70px;
  padding-bottom:70px;


  .contentHeaderWrapper{
    display:flex;
    flex-direction: column;
    .titleInfo{
      display:block;
      text-align: center;
      font-family: 'Myriad Pro Light';
      color:#245d38;
      padding-bottom:10px;
    }
  }

  .lastArticlesTitle{
    color:#245d38;
    font-weight: lighter;
    text-align: center;
    font-size: 2.7rem;
    span{
      color:#82bc00;
    }

  }


  .lastArticlesContentWrapper{
    display:grid;
    grid-template-columns: repeat(3,auto);
    grid-gap: 28px;
    padding-top:60px;

    .lastArticleCard{
      position: relative;
      border:1px solid #e7e7e7;
      max-width: 368px;
      border-radius: 3px;
      box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.11);
      padding:50px 35px 50px 35px;

      .lastArticleDate{
        color:#245d38;
        font-size:0.9rem;
      }
      .lastArticleTitle{
        color:#82bc00;
        font-size:1.4rem;
        margin-top:20px;
        margin-bottom:20px;
        font-family: 'Myriad Pro Light', sans-serif;
      }
      .lastArticleContent{
        color:#363636;
        font-family: 'Myriad Pro Light', sans-serif;
        margin-bottom:30px;
        line-height: 1.4rem;

      }

      .lastArticleSep{
        height: 1px;
        background-color: #82bc00;
        border: none;
        margin-bottom:10px;       
      }

      .lastArticleWriter{
        color:#838386;
        font-style:italic;
        font-family: 'Myriad Pro Light', sans-serif;
      }


    }
  }



}