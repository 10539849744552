.navbar {
  background:rgba(255,255,255,1);
  height: 126px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 1.4rem;
  position: sticky;
  top: 0;
  z-index: 999;
  padding-bottom:15px;
  transition: all 0.5s ease;   
}

.navbar-container {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 100%;
}

.navbar-logo {
  justify-self: start;
  // margin-left: 20px;
  position: absolute;
  max-width: 225px;
  left:120px;
  top:-13px;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  transition: all 0.5s ease; 
  // transition: all 0.5s ease; 
  // box-shadow: 0 6px 35px 0 rgba(0, 0, 0, 0.25);
  // border-radius: 50%;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  // width: 60vw;
  width: 100%;
  justify-content: end;
  padding-right:70px
}


.nav-item {
  height: 40px;
  display: flex;
}

.rightMenuButton{
  position: absolute;
  border:none;
  background:none;
  align-self: center;
  justify-self: center;
  outline: none;
  right:23px;
  width:30px;
  height: 30px;
  opacity: 1;
  transition: all 0.5s ease; 
  font-size:1.8rem;
  color:#8b8a8f;

  &:hover{
    cursor:pointer;
    color:#245d38;
  }

  &.hide{
    opacity: 0;
    transition: all 0.5s ease; 
  }

}

.nav-links {
  color: #8b8a8f;
  display: flex;
  align-items: center;
  text-decoration: none;
  // padding: 0.1rem 1rem;
  margin-right:20px;
  height: 100%;
  box-sizing: content-box;
  font-family: 'Myriad Pro Light',sans-serif;  
  
}

.nav-links:hover {
  color:#245d38;
  border-bottom: 3px solid #245d38;
  // transition: all 0.2s ease-out;
}

.fa-times{
  color: #245d38;
}

.fa-bars {
  color: #245d38;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
  z-index:1;
}

.nav-menu-sub {
  position: absolute;
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 30px;
  list-style: none;
  text-align: center;
  // width: 60vw;
  max-width: 400px;
  width:100vw;
  top:10px;
  right:25px;
  justify-content: end;
  
}

.nav-item-sub {
  font-family: 'Myriad Pro Light',sans-serif;  
  color:#82bc00;



  &:nth-of-type(3){
    font-family: 'Myriad Pro Regular';
    color:#245d38 !important;
    font-weight: bold;

  }

}


.navbar.smaller{
  height:80px;
  transition: all 0.5s ease; 
  box-shadow: 0 5px 5px rgba(116,116,116,.25);
  background: rgba(255,255,255,0.9);

  & .navbar-logo{
    max-height:70px;
    top:-40px;
    transition: all 0.5s ease; 
    
  }

  & .navbar-container {
    height:20px;
  }

  & .nav-menu-sub {
    top:5px;
  }

}

.nav-item-for-mobile{
  display:none;
}

.navmenuCloseBtn{
  background:url('/assets/images/close-button-white.png');
  width:38px;
  height:38px;
  display:block;
  position:absolute;
  top:42px;
  right:31px;
  border:none;
  outline:none;
  cursor:pointer;
}