.sliderWrapper{
  display: grid;
  height:100vh;
  max-height: 700px;
  grid-template-columns: 68% 32%;
}

.leftSlider{
  position: relative;
  background: url('/assets/images/hotspotbg1.jpg') center center/cover no-repeat;
  display:flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;

  .sosoSlogan{
    color:#fff;
    font-size:2.45rem;
    font-weight: normal;
    font-style: normal;
    max-width: 250px;
    text-align: center;
    position: relative;
    top:-10px;
    left:105px;
    font-family: 'Myriad Pro Semibold Italic', sans-serif;
  }

  .video-caption-text{
    font-family: 'Myriad Pro Regular', sans-serif;
    font-weight: normal;
    font-size: 1.5rem;
    max-width: 180px;
  }


  .watchVideoButtonWrapper{
    position: relative;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width:330px;
    height: 110px;
    background:#245d38;
    color:#82bc00;
    padding:30px;
    left:70px;
    top:125px;
  }

  
}
.rightSlider{
  display:grid;
  grid-template-columns:1fr 1fr;
  grid-template-rows: 300px 1fr;
  grid-template-areas:
    "filterBox1 filterBox2"
    "filterBox3 filterBox3";
  background-image: url('/assets/images/filter-bg.jpg');
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  grid-gap:1px;

}
.filterBox1{
  position: relative;
  background:rgba(36,93,56,0.97);

  .signUpButtonWrapper{
    position: absolute;
    width: 100%;
    height: 100%;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    padding:50px 38px 50px 38px;
    transition: background 1s;

    &:hover{
      background: darken(rgba(36,93,56,0.5),10%);
      cursor:pointer;
    }    

    .signUpButton{
      max-width: 92px;
      max-height: 100px;
      width:100vw;
      height: 100vh;
      border:none;
      background:none;
      
      &:hover{
        cursor:pointer;
      }
      
  
    }
  
    .signUpButtonText{
      color:#fff;
      font-family: 'Myriad Pro Light';
      font-weight: 300;
      font-size:1.8rem;
      line-height:1.7rem;
    }    
    
  }

}
.filterBox2{
  position: relative;
  background:rgba(36,93,56,0.90);

  .recommendButtonWrapper{
    position: absolute;
    width: 100%;
    height: 100%;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    padding:50px 38px 50px 38px;
    transition: background 1s;

    &:hover{
      background: darken(rgba(36,93,56,0.5),10%);
      cursor:pointer;
    }


    .recommendButton{
      max-width: 113px;
      max-height: 101px;
      width:100vw;
      height: 100vh;
      border:none;
      background:none;
      
      &:hover{
        cursor:pointer;
      }
      
  
    }
  
    .recommendButtonText{
      color:#fff;
      font-family: 'Myriad Pro Light';
      font-weight: 300;
      font-size:1.8rem;
      line-height:1.7rem;
      padding-bottom:25px;
    }  
  
  }  
}
.filterBox3{
  position: relative;
  background:rgba(36,93,56,0.90);
  grid-column:1/3;
  display:flex;
  flex-direction: column;
  padding:20px 38px 30px 38px;  

  .findWomanExpertHeader{
    color:#fff;
    padding-bottom:20px;
    font-size:2rem;
  }

  .filterForm{
    display:flex;
    height:100%;
    flex-direction: column;
    justify-content: space-between;
  }

  .filterFormWrapper{
    display:grid;

    input[type=text], select, textarea {
      width: 100%;
      padding: 6px;
      height:36px;
      border: 1px solid #ccc;
      border-radius: 4px;
      resize: vertical;
      font-family: 'Myriad Pro Regular';
      font-size:1rem;
      
      outline: #82bc00 !important;
    }
    label {
      display: flex;
      text-align:left;
      font-size:1.3rem;
      width:100%;
      color:#fff;
      font-family: 'Myriad Pro Light';
    }

    .col-30 {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      float: left;
      width: 30%;
      height: 100%;
    }
    
    .col-70 {
      float: left;
      width: 70%;
      margin-top: 6px;
    }    

  }

  .submitFilterButton{
    border:none;
    display:flex;
    justify-content: center;
    align-items: center;
    justify-self: center;
    align-self: center;
    font-size:2rem;
    background:#82bc00;
    color:#0d2716;
    height:50px;
    width:300px;
    font-family: 'Myriad Pro Regular', sans-serif;
    border-radius: 2px;

    &:hover{
      background-color: darken(#82bc00, 5%);      
      cursor: pointer;
    }

    img{
      margin-right:10px;
    }
  }
}  

.video-play-button {
  position: relative;
  box-sizing: content-box;
  display: block;
  width: 20px;
  height: 28px;
  border-radius: 50%;
  padding: 11px 13px 11px 18px;

  &:hover{
    cursor:pointer;
  }
}

.video-play-button:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 50px;
  height: 50px;
  background: #31784a;
  border:2px solid #fff;
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
}

.video-play-button:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 50px;
  height: 50px;
  background: #245d38;
  border:2px solid #fff;
  border-radius: 50%;
  transition: all 200ms;
}

.video-play-button:hover:after {
  background-color: darken(#245d38, 10%);
}

.video-play-button img {
  position: relative;
  z-index: 3;
  max-width: 100%;
  width: auto;
  height: auto;
}

.video-play-button span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 20px solid #fff;
	border-top: 14px solid transparent;
  border-bottom: 14px solid transparent;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

.video-overlay {
  position: fixed;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.80);
  opacity: 0;
  transition: all ease 500ms;
}

.video-overlay.open {
  position: fixed;
  z-index: 1000;
  opacity: 1;
}

.video-overlay-close {
  position: absolute;
  z-index: 1000;
  top: 15px;
  right: 20px;
  font-size: 36px;
  line-height: 1;
  font-weight: 400;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  transition: all 200ms;
}

.video-overlay-close:hover {
  color: #fa183d;
}

.video-overlay iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  box-shadow: 0 0 15px rgba(0,0,0,0.75);
}
.sliderParallax{
  width:100%;
  height:100%;
  position: absolute;
}