footer{
  display:flex;
  max-height: 430px;
  height:100vh;
  background:#245d38;
  box-shadow: 0 -10px 35px 0 rgba(0, 0, 0, 0.25);  
  justify-content: center;
  align-items: center;

  .footerContentWrapper{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 40px;
    max-width: 1115px;
    width:100%;
    justify-content: center;

    .footerContent{
      position: relative;
      color:#fff;
      display: flex;
      flex-direction: column;
    }

    .bottomLogoWrapper{
      display:flex;
      align-items:center;
      margin-bottom:20px;

      h4{
        font-size:1.4rem;
        margin-left:10px;
      }

    }

    .bottomLogoLayer{
      
    }

    .bottomAbout{
      font-family: 'Myriad Pro Light',sans-serif;
      font-weight: lighter;
      font-size:1rem;
      line-height: 1.4rem;
    }

    .bottomLogo{
      max-height: 50px;
      max-width: 50px;
      box-shadow: 5px 7px 10px 0 rgba(0, 0, 0, 0.75);
      border-radius: 50%;
      align-items:center;
      justify-content: center;
    }

    .bottomTitle{
      font-size:1.3rem;
      font-family: 'Myriad Pro Light',sans-serif;
      margin-bottom:10px;
    }

    .bottomContactTitle{
      font-size:1rem;
      color:#82bc00;
      margin-bottom:5px;
      margin-top:15px;
      font-family: 'Myriad Pro Light',sans-serif;
    }

    .bottomMenu{
      list-style: none;
      margin-top:10px;
      li{
        margin-left:15px;

        &:hover{
          color: #82bc00;
          cursor: pointer;
        }

        &:before{
          content: "\2022";
          color: #82bc00;
          font-weight: lighter;
          display: inline-block; 
          width: 1em;
          margin-left: -1em;  
          font-size:1.1rem;  
          font-family: 'Myriad Pro Light',sans-serif;              
          margin-bottom:5px;
        }
      }
    }

    .bottomSocial{
      margin-top:10px;
      list-style: none;
      display: grid;
      grid-template-columns: repeat(6, auto);
      grid-gap: 20px;
      list-style: none;
      text-align: center;
      // width: 60vw;
      width: 100%;
      justify-content: start;

      li{
        display:flex;
        align-items: center;
      }

      .fab{
        font-size:1.4rem;

        &:hover{
          color:#82bc00;
          cursor:pointer;
        }
      }

    }



  }

}