.articleContent {
  max-height: 200px;
  display: flex;
  flex-direction: column;  

  img{
    object-fit: contain;
    width:100%;
    height:100%;
    box-shadow: 0 6px 35px 0 rgba(0, 0, 0, 0.2);
    margin-bottom:10px;
  }

  .articleTitle{
    font-family: 'Myriad Pro Light',sans-serif;
    font-weight: 600;
  }

}
.swiper-pagination-bullet-active{
  background:#245d38 !important;
}
.swiper-pagination.swiper-pagination-bullets{
  position: absolute;
  bottom:-10px !important;
}