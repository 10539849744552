.hightLightsWrapper{
  width: 0px;
  height: 100vh;
  position: fixed;
  top: 0px;
  right: 0;
  opacity: 0;
  display: flex;
  transition: all 0.5s ease; 

  .hightLightsContentWrapper{
    display: flex;
    padding:70px 30px 110px 30px; 
    justify-content: space-around;
    align-content: center;
    flex-direction: column;
    height: 100vh;
  }


  &.active{
    width: 440px;
    
    flex-direction: column;
    background:rgba(255,255,255,0.92);
    
    opacity: 1;
    transition: all 0.5s ease;
    z-index:2;
    box-shadow: 0 6px 35px 0 rgba(0, 0, 0, 0.25);
  }

  .highlightsCloseBtn{
    background:url('/assets/images/close-button.png');
    width:38px;
    height:38px;
    display:block;
    position:absolute;
    top:42px;
    right:31px;
    border:none;
    outline:none;
    cursor:pointer;
  }

  .highlightsTitle{
    color:#245d38;
    font-weight: lighter;
    align-self: center;
    text-align: center;

    span{
      color:#82bc00;
    }

  }


  .highlightsFeaturedContent{
    height:290px !important;
  }

  .highlightsContent{
    position: relative;
    display:flex;
    width:100%;
    max-width: 300px;
    height:175px;
    align-self: center;
    padding-top:30px;
    
    .swiper-container{
      width:100%;

      .swiper-slide{
        width: 300px;
      }
    }

  }


  .highlightsEducationsWrapper{
    display:flex;
    flex-direction: column;
  }

  .highlightsArticlesWrapper{
    display:flex;
    flex-direction: column;
  }


}

.highlightSep{
  border:1px solid #82bc00;
  max-width: 270px;
  width:100%;
  align-self: center;
}

