.educationDateTime{
  color:#699801;
  font-size:1rem;
  text-align: center;
  font-family:'Myriad pro light';
  font-weight: bold;
  padding:0px;
}
.educationTitle{
  font-size:1.7rem;
  font-family:'Myriad pro light';
  font-style: italic;
  text-align: center;
  color:#245d38;
  margin-top:15px;
  margin-bottom:15px;

  &:before{
    content:'"'
  }

  &:after{
    content:'"'
  }
}

.educationAddress{
  font-size:1.2rem;
  font-family:'Myriad pro light';
  text-align: center;
}
.swiper-button-prev, .swiper-button-next{
  width:30px !important;
  height:30px !important;
  color:#245d38 !important;
  font-weight: bold;
  top:70% !important;
  outline:none !important;

  &:after{
    font-size:16px !important;    
  }
}
.swiper-button-prev{
  left:0px !important;
}
.swiper-button-next{
  right:0px !important;
}

