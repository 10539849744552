.lastExpertsWrapper{
  display:flex;
  flex-direction: column;
  position: relative;
  max-height: 700px;
  max-width: 1520px;
  width:100%;
  height: 100vh;
  justify-self:center;
  padding-top:70px;
  padding-bottom:70px;

  .contentHeaderWrapper{
    display:flex;
    flex-direction: column;
    .titleInfo{
      display:block;
      text-align: center;
      font-family: 'Myriad Pro Light';
      color:#245d38;
      padding-bottom:10px;
    }
  }

  .lastExpertsTitle{
    color:#245d38;
    font-weight: lighter;
    text-align: center;
    font-size: 2.7rem;
    span{
      color:#82bc00;
    }

  }

  .lastExpertsContentWrapper{
    display:grid;
    grid-template-columns: repeat(5,auto);
    grid-gap: 28px;
    padding-top:60px;


    .lastExpertCard{
      position: relative;

      .lastExpertImg{
        box-shadow: 0 0px 15px 0 rgba(0, 0, 0, 0.20);
        margin-bottom:25px;
        width: 100%;
        height: auto;
      }

      .lastExpertName{
        color:#82bc00;
        font-size:1.45rem;
        font-family: 'Myriad Pro Regular';
        font-weight: 300;
        margin-bottom:5px;
      }
      .lastExpertTitle{
        color:#838386;
        font-family:'Myriad Pro Light';
        font-weight: lighter;
        font-style: italic;
        font-size: 1rem;
      }

      .lastExpertDetailBtn{
        margin-top:20px;
        width:152px;
        height:42px;

        &:hover{
          background-color:#245d38;
          color:#fff;
          padding:8px 20px;
          border:1px solid #ededed;
          transition: all 0.3s ease-out;
          border-radius: 3px;
          cursor:pointer;
        }

      }
      
    }

  }


}