.textContentGalleryWraper{
  display:flex;
  max-height: 930px;
  height: 100vh;
  width: 100%;
  background: linear-gradient(to left, #fff 33%, #f8f8f8 33%);


  .textContentGalleryInnerWrapper{
    display:grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows:1fr;
    width:100%;
    height:100%;


    .textContentGalleryLeft{
      display:flex;
      width:100%;
      height:100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-left: 200px;
      box-sizing: content-box;     
      background: url(/assets/images/cubes-reverse.png);
      background-repeat: no-repeat;
      background-position:center;

      .textContentGalleryTitleWrapper{
        max-width: 420px;
        min-height: 420px;
        padding-right:30px;
    
        .textContentGalleryTitleInfo{
          display:block;
          text-align: left;
          font-family: 'Myriad Pro Light';
          color:#245d38;
          padding-bottom:10px;    
        }
      
        .textContentGalleryTitle{
          color:#245d38;
          font-weight: lighter;
          text-align: left;
          font-size: 2.7rem;
          max-width: 420px;
          span{
            color:#82bc00;
          }    
        }   
      }
  

      .textContent{
        color:#535353;
        font-family: 'Myriad Pro Light',sans-serif;
        font-size:1.15rem;
        line-height: 1.7rem;
        margin-bottom:30px;
        max-width: 420px;
        margin-top:40px;
      }
  
      .textContentButton{
        position: relative;
        border:none;
        background:transparent;
        text-align:left;
        color:#82bc00;
        width: 60px;
        font-family: 'Myriad Pro Light', sans-serif;
        font-size:1.2rem;
        outline:none;
        padding-bottom:5px;
        
  
        &:hover{
          cursor:pointer;
          border-bottom:2px solid #82bc00;
          
        }
        
      }      

    }

    .textContentGalleryRight{
      display:flex;
      width:100%;
      height:100%;
      flex-direction: column;
      justify-content: center;

      .galleryImgWrapper{
        max-width: 614px;
        max-height: 614px;
        width: 100%;
        height:100%;
        box-shadow: 0 6px 35px 0 rgba(0, 0, 0, 0.25);
        background:#fff;
      }


      .galleryNav{
        display:flex;
        margin-top:20px;
        flex-direction: row;
        justify-content: flex-end;
        max-width: 614px;

        button{
          background:transparent;
          border:none;
          margin-left:20px;
          margin-right:20px;
        }

      }

    }

 


  }


}