.copyrightLayer{
  display:flex;
  height: 55px;
  background:#14341f;
  justify-content: center;
  align-items: center;
  
  
  .copyrightContent{
    display:flex;
    width: 100%;
    max-width: 1115px;
    padding-left:10px;
    padding-right:10px;

    justify-content: space-between;

    .copyrightId{
      color:#fff;
      font-family: 'Myriad Pro Light';
      font-weight: lighter;
    }
    .nav-bottom {
      position: relative;
      display: grid;
      grid-template-columns: repeat(3, auto);
      grid-column-gap: 15px;
      list-style: none;
      text-align: center;
      // width: 60vw;
      max-width: 320px;
      font-weight: lighter;
      width:100vw;
      
    }
    
    .nav-item-bottom{
      font-family: 'Myriad Pro Light',sans-serif;  
      color:#fff;
      font-weight: lighter;

      &:hover{
        cursor: pointer;
        color:#82bc00;
      }
      
    }
    




  }

}