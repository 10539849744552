.newsSubscriberWrapper{
  display:grid;
  grid-template-columns: 40% 60%;
  grid-template-rows:1fr;
  height:100vh;
  max-height: 285px;
  background:#557b00;
  box-shadow: 0 0px 35px 0 rgba(0, 0, 0, 0.25);  
  z-index:1;

  .newsSubscriberLeft{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    box-sizing: content-box;
    padding-right:150px;
    background:
      linear-gradient(
        rgba(130, 188, 0, 0.30), 
        rgba(130, 188, 0, 0.30)
      ),
      /* bottom, image */
      url('/assets/images/newsletterbg.jpg') no-repeat center top / cover;

      .newsSubscriberTitle{
        color:#fff;
        font-family: 'Myriad Pro Regular',sans-serif;
        font-size:3.7rem;
        max-width: 277px;
        font-weight: lighter;
        
        
      }
  }

  .newsSubscriberRight{
    display: flex;
    flex-direction: column;
    padding:85px 75px 85px 75px;
    align-items: center;

    .newsSubscriberInfo{
      color:#fff;
      font-size:1.3rem;
      max-width: 705px;
      font-family: 'Myriad Pro Light',sans-serif;
    }

    .newsInputWrapper{
      position: relative;
      display:flex;
      align-items: center;
      padding-top:20px;
      max-width: 705px;

      input{
        height:42px;
        border-radius: 3px;
        border:1px solid #ededed;
        margin-right:10px;
        width: 100%;
        padding-left:10px;
        font-size:1rem;
        color:#333;
        outline:none;
      }

    }

    .buttonSubscriber{
      width:152px;
      height:42px;
      background:transparent;
      color:#fff;

      &:hover{
        background-color:#245d38;
        color:#fff;
        padding:8px 20px;
        border:1px solid #ededed;
        transition: all 0.3s ease-out;
        border-radius: 3px;
        cursor:pointer;
        outline:none;
      }

    }

  }

  

}