.textContentWrapper{
  display:grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows:1fr;
  max-height: 490px;
  max-width: 950px;
  width: 100%;
  height: 100vh;
  justify-self:center;
  background: url(/assets/images/cubes.png);
  background-repeat: no-repeat;
  background-position-x: 80px;
  background-position-y: 40px;  
  margin-top:60px;

  .textContentTitleInfo{
    display:block;
    text-align: left;
    font-family: 'Myriad Pro Light';
    color:#245d38;
    padding-bottom:10px;    
  }

  .textContentTitle{
    color:#245d38;
    font-weight: lighter;
    text-align: left;
    font-size: 2.7rem;
    max-width: 420px;
    span{
      color:#82bc00;
    }    
  }

  .textContentLeftWrapper{
    display:flex;
    width:100%;
    height:100%;
  }

  .textContentRightWrapper{
    display:flex;
    flex-direction: column;

    .textContent{
      color:#535353;
      font-family: 'Myriad Pro Light',sans-serif;
      font-size:1.15rem;
      line-height: 1.7rem;
      margin-bottom:30px;
    }

    .textContentButton{
      position: relative;
      border:none;
      background:transparent;
      text-align:left;
      color:#82bc00;
      width: 60px;
      font-family: 'Myriad Pro Light', sans-serif;
      font-size:1.2rem;
      outline:none;
      padding-bottom:5px;
      

      &:hover{
        cursor:pointer;
        border-bottom:2px solid #82bc00;
        
      }
      
    }

  }

}